import { Routes, Route } from "react-router-dom";
import TopBar from "./scence/global/TopBar";
import Team from "./scence/team";
// import { useEffect } from "react";
// import firebase from "./Config";
// import { useState } from "react";

// const database = firebase.database();



function App() {


  // let [data, setData] = useState([])

  // database.ref('testing/').once('value', (snap) => {
  //   let towerData = snap.val()
  //   let keys = snap.keys()
  //   keys.map((towerId) => {
  //     setData({
  //       // id: 1,
  //       tower: "Tower " + towerId.towerName,
  //       tankDomestic: towerId.domestic.level,
  //       valueDomestic: towerId.domestic.valveState,
  //       tankFlush: towerId.flush.level,
  //       valueFlush: towerId.flush.valveState,
  //       state: towerId.state
  //     })
  //   })
  //   console.log("--------first time--------", towerData)

  // })

  // // database.ref('testing/').on('child_changed', (snap) => {
  // //   let towerData = snap.val()
  // //   console.log("--------tower Data---change value -----", towerData)
  // // })



  return (
    <div className="app">
      <main className="content">
        {/* <TopBar /> */}
        <Routes>
          <Route path="/" element={<Team />} />
        </Routes>

      </main>
    </div>
  );
}

export default App;
