// import React from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';


const firebaseConfig = {
    apiKey: "AIzaSyBi_aACnylUzkFmpuYSMx3MSZ8hFUfUDkQ",
    authDomain: "test-8629f.firebaseapp.com",
    databaseURL:
        "https://test-8629f-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "test-8629f",
    storageBucket: "test-8629f.appspot.com",
    messagingSenderId: "95517656018",
    appId: "1:95517656018:web:bea84849e69d18a2d6fe0b",
    measurementId: "G-LNW8P1Q84R",
};

firebase.initializeApp(firebaseConfig);


export default firebase;