import React from "react";
import firebase from "../../Config";
import { Circles } from 'react-loader-spinner'
import { Box, Typography, Button } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
// import { towerDataArr } from "../../data/mockData"
import Header from "../../components/Header"
import { useState } from "react";
import { useEffect } from "react";
import { borderRadius } from "@mui/system";
import { red } from "@mui/material/colors";
const database = firebase.database();





const Team = () => {
  let [data, setData] = useState(null)
  // let [motorData, setMotorData] = useState(null)

  useEffect(() => {

    const fetchData = async () => {
      try {
        const testingRef = database.ref('tower/');
        const testingMotorRef = database.ref('waterMotor/');

        testingRef.on('value', (testingSnapshot) => {
          const towerData = testingSnapshot.val();

          testingMotorRef.on('value', (motorSnapshot) => {
            const motorData = motorSnapshot.val();

            const qrstdomesticMotorStatus = motorData.domesticMotor.C011657092236316.status;
            const qrstflushMotorStatus = motorData.flushMotor.C011657092236319.status;
            const e2jdomesticMotorStatus = motorData.domesticMotor.C011677224456543.status;
            const e2jflushMotorStatus = motorData.flushMotor.C011677224456544.status;
            // console.log("uvwFlushMotorStatus--------", motorData.flushMotor.C011643958482986.status)
            // console.log("uvwDomesticMotorStatus--------", motorData.domesticMotor.C011643958482984.status)
            const uvwDomesticMotorStatus = motorData.domesticMotor.C011643958482984.status;
            const uvwFlushMotorStatus = motorData.flushMotor.C011643958482986.status;




            let towerDataArr = Object.values(towerData)
              .map((towerId, index) => {
                if (towerId.domestic) {
                  let obj = {
                    id: index + 1,
                    tower: "Tower " + towerId.towerName,
                    tankDomestic: towerId.domestic.level,
                    valueDomestic: towerId.domestic.valveState,
                    tankFlush: towerId.flush.level,
                    valueFlush: towerId.flush.valveState,
                    controllerState: towerId.state,
                    operatingMode: towerId.operatingMode
                  };

                  if (
                    towerId.towerName === 'S' ||
                    towerId.towerName === 'T' ||
                    towerId.towerName === 'R' ||
                    towerId.towerName === 'Q' ||
                    towerId.towerName === 'QRST Reservoir'
                  ) {
                    obj['domesticMotor'] = qrstdomesticMotorStatus;
                    obj['flushMotor'] = qrstflushMotorStatus;
                  } else if (
                    towerId.towerName === 'E' ||
                    towerId.towerName === 'F' ||
                    towerId.towerName === 'G' ||
                    towerId.towerName === 'H' ||
                    towerId.towerName === 'I' ||
                    towerId.towerName === 'J' ||
                    towerId.towerName === 'EtoJ Reservoir'
                  ) {
                    obj['domesticMotor'] = e2jdomesticMotorStatus;
                    obj['flushMotor'] = e2jflushMotorStatus;
                  } else if (
                    towerId.towerName === 'U' ||
                    towerId.towerName === 'V' ||
                    towerId.towerName === 'W'
                  ) {
                    obj['domesticMotor'] = uvwDomesticMotorStatus;
                    obj['flushMotor'] = uvwFlushMotorStatus;
                  }

                  return obj;
                }
              })
              .filter(Boolean);

            // Rearrange the array
            const qrstReservoirIndex = towerDataArr.findIndex(
              (tower) => tower.tower === 'Tower QRST Reservoir'
            );
            const e2jReservoirIndex = towerDataArr.findIndex(
              (tower) => tower.tower === 'Tower EtoJ Reservoir'
            );
            if (qrstReservoirIndex !== -1 && e2jReservoirIndex !== -1) {
              const qrstReservoir = towerDataArr.splice(qrstReservoirIndex, 1)[0];
              const e2jReservoir = towerDataArr.splice(e2jReservoirIndex - 1, 1)[0];
              towerDataArr.unshift(qrstReservoir);
              towerDataArr.splice(1, 0, e2jReservoir);
            }

            console.log("array---------", towerDataArr);
            setData(towerDataArr);
          });
        });
      } catch (error) {
        console.log("-----error-----", error);
      }
    };

    fetchData();

  }, []);

  let column = [
    {
      field: "tower",
      headerName: "Towers",
      headerClassName: "header-column ",
      flex: 0.8,
      renderCell: ({ row: { tower } }) => {
        return (
          <Button variant="contained">
            <Typography sx={{ ml: "5px" }}>
              {tower}
            </Typography>
          </Button>


        )
      }
    },
    {
      field: "tankDomestic",
      headerName: "Tank Level Domestic",
      flex: 0.5,
      headerClassName: "header-column ",

      cellClassName: "name-column--cell",
      renderCell: ({ row: { tankDomestic } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor={
              tankDomestic === "high" ? "green" : "#8B0000"     //#8B0000//
            }
            borderRadius="4px"
          >
            {/* {tankDomestic === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {tankDomestic === "manager" && <SecurityOutlinedIcon />}
            {tankDomestic === "user" && <LockOpenOutlinedIcon />} */}
            {/* <Button variant="text" size="small"> */}
            <Typography color="white" sx={{ ml: "5px" }}>
              {tankDomestic}
            </Typography>
            {/* </Button> */}

          </Box>
        )
      }
    },
    {
      field: "valueDomestic",
      headerName: "Domestic Valve State",
      flex: 0.6,
      headerClassName: "header-column ",
      cellClassName: "name-column--cell ",
      renderCell: ({ row: { valueDomestic } }) => {
        return (
          <Box
            width="60px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor={valueDomestic === "on" ? "green" : "#8B0000"}
            borderRadius="4px"
          ><Typography color="white" sx={{ ml: "5px" }}>
              {valueDomestic}
            </Typography>
          </Box>
        )
      }
    },
    {
      field: "domesticMotor",
      headerName: "Domestic Motor",
      flex: 0.5,
      headerClassName: "header-column ",
      renderCell: ({ row: { domesticMotor } }) => {
        return (
          <Box
            width="60px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor={domesticMotor === "on" ? "green" : "#8B0000"}
            borderRadius="4px"
          ><Typography color="white" sx={{ ml: "5px" }}>
              {domesticMotor}
            </Typography>
          </Box>
        )
      }
    },
    {
      field: "tankFlush",
      headerName: "Tank Level Flush ",
      flex: 0.5,
      headerClassName: "header-column ",
      cellClassName: "name-column--cell ",
      renderCell: ({ row: { tankFlush } }) => {
        return (
          <Box
            width="60px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor={tankFlush === "high" ? "green" : "#8B0000"}
            borderRadius="4px"
          >
            <Typography color="white" sx={{ ml: "5px" }}>
              {tankFlush}
            </Typography>

          </Box>
        )
      }
    },
    {
      field: "valueFlush",
      headerName: "Flush Valve",
      flex: 0.5,
      headerClassName: "header-column ",
      cellClassName: "name-column--cell ",
      renderCell: ({ row: { valueFlush } }) => {
        return (
          <Box
            width="60px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor={valueFlush === "on" ? "green" : "#8B0000"}
            borderRadius="4px"
          >
            <Typography color="white" sx={{ ml: "5px" }}>
              {valueFlush}
            </Typography>
          </Box>

        )
      }
    },
    {
      field: "flushMotor",
      headerName: "Flush Motor",
      flex: 0.5,
      headerClassName: "header-column ",
      renderCell: ({ row: { flushMotor } }) => {
        return (
          <Box
            width="60px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor={flushMotor === "on" ? "green" : "#8B0000"}
            borderRadius="4px"
          >
            <Typography color="white" sx={{ ml: "5px" }}>
              {flushMotor}
            </Typography>
          </Box>

        )
      }
    },
    {
      field: "controllerState",
      headerName: "Device state",
      flex: 0.5,
      headerClassName: "header-column",
      cellClassName: "name-column--cell",
      renderCell: ({ row: { controllerState } }) => {
        const stateText = controllerState ? "true" : "false";
        return (
          <Box
            width="60px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor={controllerState ? "green" : "#8B0000"}
            borderRadius="4px"
          >
            <Typography color="white" sx={{ ml: "5px" }}>
              {stateText}
            </Typography>
          </Box>
        )
      }
    },
    {
      field: "operatingMode",
      headerName: "Operating Mode",
      flex: 0.5,
      headerClassName: "header-column",
      cellClassName: "name-column--cell",
      renderCell: ({ row: { operatingMode } }) => {
        return (
          <Box
            width="60px"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundColor={
              operatingMode === "auto" ? "green" : "#8B0000"}
            borderRadius="4px"
          >
            <Typography color="white" sx={{ ml: "5px" }}>
              {operatingMode}
            </Typography>
          </Box>
        )
      }
    },

  ]


  return (
    <Box m="20px">
      <Header title="Jade Residencies" subtitle="" />
      <Box m="0 0 0 0" height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            // borderBottom: "none",
            border: "1px solid #999999 !important",
            padding: "8px",
          },
          "& .header-column": {
            color: "darkBlue",
            fontSize: "16px",
            textAlign: "center",

          },
          "& .name-column--cell": {
            color: "darkBlue",
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "lightGray",
            borderBottom: "none",
            borderRight: "2px solid #999999 !important",
          },


          "& .MuiDataGrid-virtualScrollbar": {
            backgroundColor: "gray",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: "lightGray",
          }

        }}
      >{data ? (
        <Box >
          <DataGrid rows={data} columns={column} rowHeight={45} headerHeight={100} />

        </Box>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: "10%" }}>
          <Circles
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </Box>

      )}

      </Box>
    </Box >
  )
};

export default Team;
